/* eslint-disable max-len */
// List of valid Birmingham Outward Postcodes
const birminghamOutcodes = [
    "B1", "B10", "B11", "B12", "B13", "B14", "B15", "B16", "B17", "B18", "B19", // 11
    "B2", "B20", "B21", "B23", "B24", "B25", "B26", "B27", "B28", "B29", // 10
    "B3", "B30", "B31", "B32", "B33", "B34", "B35", "B36", "B37", "B38", // 10
    "B4", "B40", "B42", "B43", "B44", "B45", "B46", "B47", "B48", "B49", // 10
    "B5", "B50", // 2
    "B6", "B60", "B61", "B62", "B63", "B64", "B65", "B66", "B67", "B68", "B69", // 11
    "B7", "B70", "B71", "B72", "B73", "B74", "B75", "B76", "B77", "B78", "B79", // 11
    "B8", "B80", // 2
    "B9", "B90", "B91", "B92", "B93", "B94", "B95", "B96", "B97", "B98", // 10
];
const allowedOutcodes = [...birminghamOutcodes];
export default function testOutcode(value) {
    // If the string starts with a valid Birmingham Outward Postcode, return true
    const isValid = (allowedOutcodes.filter((outcode) => value.toUpperCase().startsWith(outcode.toUpperCase()))).length > 0;
    return isValid;
}
;
